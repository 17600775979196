/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
const aviableDocuments = [
  ['colfactura', 'CC', 'N'],
  ['colfactura', 'CE', 'N'],
  ['colfactura', 'NIT', 'J'],
  ['colnomina', 'CC', 'N'],
  ['colnomina', 'CE', 'N'],
  ['colnomina', 'NIT', 'J'],
  ['firmaya', 'CC', 'N'],
  ['firmaya', 'NIT', 'J'],
  ['superguarda', 'CC', 'N'],
  ['superguarda', 'CE', 'N'],
  ['superguarda', 'PA', 'N'],
  ['superguarda', 'NIT', 'J'],
  ['bemyself', 'CC', 'N'],
  ['bemyself', 'CE', 'N'],
  ['bemyself', 'PA', 'N'],
  ['bemyself', 'NIT', 'J'],
  ['license', 'CC', 'N'],
  ['license', 'CE', 'N'],
  ['license', 'PA', 'N'],
  ['license', 'NUIP', 'N'],
  ['license', 'NIT', 'J'],
];

/**
 * Get list of aviable documents for each aplication,
 * this filter is according to documentsTypes.js aviable in scr/helpers
 * @param documentTypes to filter
 * @param {*} personType personType for data facturaction
 * and personTypeService for data activation license
 * @param appName current aplication
 * @returns avaibles documents for each application and typePerson
 */
export const getDocumentTypes = (documentTypes, personType, appName) => {
  const result = [];
  const forCurrentApp = aviableDocuments.filter(
    (current) => current[0] === appName && current[2] === personType,
  );
  documentTypes.forEach((currentDoc) => {
    forCurrentApp.forEach((currentApp) => {
      if (currentDoc.documentTypeCode === currentApp[1]) {
        result.push({
          id: currentDoc.value,
          label: currentDoc.text,
          value: currentDoc.value,
          text: currentDoc.text,
        });
      }
    });
  });
  return result;
};

export const getDocumentTypesContracts = (documentTypes, personType, appName, allowedDocumentTypes, isSupervisor) => {
  const result = [];
  let allowedForAppAndPerson = null;
  if (!isSupervisor) {
    allowedForAppAndPerson = allowedDocumentTypes.filter(
      (item) => item.appName.toUpperCase() === appName.toUpperCase() && item.personTypeAllowed === personType && item.isVisibleForClient === true,
    );
  } else {
    allowedForAppAndPerson = allowedDocumentTypes.filter(
      (item) => item.appName.toUpperCase() === appName.toUpperCase() && item.personTypeAllowed === personType && item.isVisibleForSupervisor === true,
    );
  }
  documentTypes.forEach((currentDoc) => {
    const isAllowed = allowedForAppAndPerson.some(
      (allowedDoc) => allowedDoc.documentTypeAllowed === currentDoc.documentTypeCode,
    );

    if (isAllowed) {
      result.push({
        id: currentDoc.value,
        label: currentDoc.text,
        value: currentDoc.value,
        text: currentDoc.text,
      });
    }
  });

  return result;
};
